import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Navigation = () => {
  return (
    <div className="nav">
      <a className="logo" href="/">Vippsi</a>
      <nav className="navigation-bar">
        <ul>
          <li>Blog</li>
          <AnchorLink href="#projects">
            <li>Projects</li>
          </AnchorLink>
          <AnchorLink href="#contact">
            <li>Contact</li>
          </AnchorLink>
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
