import React from "react";

const ProjectCard = (project) => {
  return (
    <div className="test-div">
      <div className="project-card-container">
        <div className="project-card-image">
          <h2>{project.project.title}</h2>

          <img
            src={`${project.project.img}`}
            alt="test"
          />
        </div>
        <div className="project-card-text">
          <h3>Project description</h3>
          <p>{project.project.description}</p>
        </div>
        <div className="project-card-tech-stack">
          <h3>Tech Stack</h3>
          <p> {project.project.tech_stack.split(",").join(" | ")}</p>
        </div>
        <div className="project-card-buttons">
          {project.project.github_code && (
            <button
              className="button-primary"
              onClick={(e) => {
                e.preventDefault();
                window.open(project.project.github_code, "_blank");
              }}
            >
              Code
            </button>
          )}
          {project.project.live_site && (
            <button
              className="button-secondary"
              onClick={(e) => {
                e.preventDefault();
                window.open(project.project.live_site, "_blank");
              }}
            >
              Live
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
