import React, { useState, useEffect } from 'react';
import ProjectCard from './projectCard';
import Pagination from './pagination';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
// import axios from "axios";
import bridges from '../img/brdiges.png';
import expat from '../img/expat.png';
import calc from '../img/calc.PNG';

let newProjects = [
  {
    title: 'Bridges to Prosperity',
    img: `${bridges}`,
    description:
      'Bridges to Prosperity helps with the development of footbridges in third-world countries.',
    tech_stack:
      'React, JavaScript, HTML/CSS, Node.js, Express, Mapbox API, Docker, FastAPI, Postgresql',
    github_code:
      'https://github.com/Lambda-School-Labs/bridges-to-prosperity-fe-a',
  },
  {
    title: 'Expat Journal',
    img: `${expat}`,
    description:
      'This was a team project to give expats a place to upload and save their stories',
    tech_stack: 'Node.js, Express.js, Bcrypt, JWT, Supertest, Jest',
    github_code: 'https://github.com/Expat-Journal-21/Backend',
  },
  {
    title: 'The Calculator',
    img: `${calc}`,
    description:
      'This was a personal project to show the fundamental skills of React and JavaScript',
    tech_stack: 'JavaScript, React, SASS, Vercel',
    live_site: 'https://the-calculator.vercel.app/',
    github_code: 'https://github.com/Vippsi/TheCalculator',
  },
];

const Projects = () => {
  const [projects, setProjects] = useState(newProjects);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage] = useState(3);

  // useEffect(() => {
  //   const fetchProjects = () => {
  //     setLoading(true);
  //     axios
  //       .get(`${process.env.REACT_APP_DATABASE_URL}/projects`)
  //       .then((res) => {
  //         setProjects(res.data);
  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };

  //   fetchProjects();
  // }, []);

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = projects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );
  const paginate = (number) => setCurrentPage(number);
  return (
    <div id='projects' className='projects-container'>
      <h2 className=' projects-h2 light-mode'>Projects</h2>
      {loading === true ? (
        <Loader
          type='TailSpin'
          color='#f48769'
          height={80}
          width={80}
          radius={1}
        />
      ) : (
        <div>
          {currentProjects.map((project) => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </div>
      )}
      {projects.length <= projectsPerPage ? (
        ''
      ) : (
        <Pagination
          projectsPerPage={projectsPerPage}
          totalProjects={projects.length}
          paginate={paginate}
        />
      )}
    </div>
  );
};

export default Projects;
