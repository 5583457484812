import React from "react";

const Footer = () => {
  return (
    <div className="footer-container">
      <p>© Jonathan Thornton | All Rights Reserved</p>
      <p>
        UX Design by{" "}
        <a href="https://twitter.com/songadev" target="_blank" rel="noreferrer">
          Songa Mugenzi
        </a>
      </p>
    </div>
  );
};

export default Footer;
