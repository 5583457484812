import React from "react";
import heroImage from "../styles/images/laptop.jpg";
import headShot from "../styles/images/Jonathan_headshot crop.jpg";

const Hero = () => {
  return (
    <div className="image-div">
      <img src={heroImage} alt="hero" className="hero-image" />
      <div className="hero-text">
        <h1>Hi, my name is Jonathan and I'm a software developer</h1>
        <div className="about-div">
          <p className="about-text">
            I am a Full Stack web developer who
            loves technology, and what it can do in our day-to-day life. I'm
            interested in working on ambitious projects, and working with
            people!
          </p>
        </div>
      </div>
      <img src={headShot} alt="profile" className="headshot" />
    </div>
  );
};

export default Hero;
