import React, { useState } from "react";

const Pagination = ({ projectsPerPage, totalProjects, paginate }) => {
  const pageDots = [];
  const [pageNumber, setPageNumber] = useState(1);

  for (let i = 1; i <= Math.ceil(totalProjects / projectsPerPage); i++) {
    pageDots.push(i);
  }

  const last = pageDots.length;

  return (
    <nav className="pagination-nav">
      <ul className="pagination">
        {pageDots.map((dot) => (
          <li key={dot}>
            <div className={dot === pageNumber ? "active" : ""}></div>
          </li>
        ))}
        <div className="arrows">
          <a
            onClick={(e) => {
              e.preventDefault();
              paginate(pageNumber - 1);
              setPageNumber(pageNumber - 1);
            }}
            className={`arrow-left ${pageNumber === 1 ? "disabled" : ""}`}
            href="#projects"
          >
            {"<"}
          </a>
          <a
            onClick={(e) => {
              e.preventDefault();

              paginate(pageNumber + 1);
              setPageNumber(pageNumber + 1);
            }}
            className={`arrow-right ${pageNumber === last ? "disabled" : ""}`}
            href="#projects"
          >
            {">"}
          </a>
        </div>
      </ul>
    </nav>
  );
};

export default Pagination;
