import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import * as yup from "yup";
import formSchema from "../Validation/formSchema";

const Contact = () => {
  const initialValues = {
    message_html: "",
    name: "",
    email: "",
  };

  const initialErrors = {
    message_html: "",
    name: "",
    email: "",
  };

  const [form, setForm] = useState(initialValues);
  const [formErrors, setFormErrors] = useState(initialErrors);
  const [sent, setSent] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleChanges = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    yup
      .reach(formSchema, name)
      .validate(value)
      .then((valid) => {
        setFormErrors({ ...formErrors, [name]: "" });
      })
      .catch((err) => {
        setFormErrors({ ...formErrors, [name]: err.errors[0] });
      });

    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    formSchema.isValid(form).then((valid) => {
      setDisabled(!valid);
    });
  }, [form]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateId = "vippsi_dev";

    const variables = {
      message_html: form.message_html,
      name: form.name,
      email: form.email,
    };

    emailjs
      .sendForm(
        "gmail",
        templateId,
        e.target,
        "user_M3JoI2zjgrWxrLIIHN191",
        variables
      )
      .then((res) => {
        setSent(true);
        setTimeout(() => {
          setSent(false);
          setForm(initialValues);
        }, 5000);
      })
      // Handle errors here however you like, or use a React error boundary
      .catch((err) =>
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occurred:",
          err
        )
      );
  };

  return (
    <div id="contact" className="contact-container">
      <h2 className=" contact-h2 light-mode">Contact Me</h2>
      <div className="form-container">
        <form className="form" onSubmit={handleSubmit}>
          <label className="input-label">
            Full Name
            <input name="name" onChange={handleChanges} value={form.name} 
            placeholder={formErrors.name ? `${formErrors.name}` : ""}
            style={formErrors.name ? {border: '2px solid red'}: null}

            />
          </label>
          {/* <p className="contact-error">{formErrors.name}</p> */}

          <label className="input-label">
            Email
            <input
              name="email"
              onChange={handleChanges}
              value={form.email}
              placeholder={formErrors.email ? `${formErrors.email}` : ""}
              style={formErrors.email ? {border: '2px solid red'}: null}
            />
          </label>
          {/* <p className="contact-error">{formErrors.email}</p> */}

          <label className="input-label">
            Message
            <textarea
              className="input-textarea"
              name="message_html"
              onChange={handleChanges}
              value={form.message_html}
            />
          </label>
          <button
            disabled={disabled}
            className={disabled ? "contact-disabled" : "contact-button"}
          >
            {sent ? "Your message was sent successfully!" : "Send"}
          </button>
          {/* {
            <p className="success-message">
              Your message was sent successfully!{" "}
            </p>
          } */}
        </form>
      </div>
    </div>
  );
};

export default Contact;
