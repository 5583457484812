import React from "react";

const Skills = () => {
  return (
    <div className='skills-container'>
      <h2 className=' skills-h2 light-mode'>Skills</h2>
      <ul className='light-mode'>
          <li className='skills-item'>HTML |</li>
          <li className='skills-item'>CSS |</li>
          <li className='skills-item'>JavaScript |</li>
          <li className='skills-item'>React |</li>
          <li className='skills-item'>Redux |</li>
          <li className='skills-item'>Node.js |</li>
          <li className='skills-item'>Express |</li>
          <li className='skills-item'>Python</li>
          
      </ul>
    </div>
  );
};

export default Skills;
